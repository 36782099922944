var $ = require("./jquery");

module.exports = function(selector) {
    $.each($(selector), function(){
        var newFormObj = $(this);
        var btn = newFormObj.find("input[type='submit']")[0];
        newFormObj.find("input[type='submit']").on("click", function(e){
            var formData = new FormData(newFormObj[0]);
            e.preventDefault();
            if (!newFormObj.hasClass("form-sending")) {
                $.ajax({
                    url: newFormObj.data("action"),
                    type:'POST',
                    processData: false,
                    contentType: false,
                    data: formData,
                    beforeSend: function () {
                        newFormObj.addClass("form-sending");
                        btn.setAttribute("disabled", true);
                    },
                    success:function(data){
                        console.log(data);
                        btn.removeAttribute("disabled");
                        // console.log(newFormObj.find(".success"));
                        var success = newFormObj.find(".success"),
                            fail =    newFormObj.find(".fail");

                        newFormObj.find(".form-answers").on("click",".close", function () {
                            success.fadeOut();
                            fail.fadeOut();
                        });
                        if (data == 200) {
                            yaCounter34259750.reachGoal('send-form');
                            newFormObj.find(".form-content").fadeOut(500, function(){
                                success.fadeIn(500, function(){
                                    setTimeout( function(){
                                        success.fadeOut(500,function(){
                                            newFormObj.find(".form-content").fadeIn(500);
                                            newFormObj.find("input[type='text']").add(newFormObj.find("textarea")).add(newFormObj.find("input[type='file']")).val("");
                                            newFormObj.find(".arm-elements_file-select-input").removeClass("file-attached");
                                            newFormObj.find(".arm-elements_file-select-input").find("file-title").find("span").text("");
                                        });
                                    },8000);
                                });

                            });
                        } else {
                            newFormObj.find(".form-content").fadeOut(500,function(){
                                fail.fadeIn(500, function(){
                                    setTimeout( function(){
                                        fail.fadeOut(500,function(){
                                            newFormObj.find(".form-content").fadeIn(500);
                                        });
                                    },8000);
                                });
                            });
                        }
                    },
                    error: function() {
                        var success = newFormObj.find(".success"),
                            fail =    newFormObj.find(".fail");
                        btn.removeAttribute("disabled");
                        newFormObj.find(".form-content").fadeOut(500,function(){
                            fail.fadeIn(500, function(){
                                setTimeout( function(){
                                    fail.fadeOut(500,function(){
                                        newFormObj.find(".form-content").fadeIn(500);
                                    });
                                },8000);
                            });
                        });
                        newFormObj.removeClass("form-sending");
                    },
                    complete: function () {
                        newFormObj.removeClass("form-sending");
                        btn.removeAttribute("disabled");
                    }
                });
            }
        });
    });
};