'use strict';
require("./polyfill");
var autoForms = require("./autoforms");
var jQuery = require("./jquery");
var maskedInput = require("./maskedinput");
var ajaxForm = require("./forms");
function i(blockname, callbackfunction) {
    callbackfunction([].slice.call(document.querySelectorAll(blockname)));
}

window.APP = {
   init: function () {
       i(".tszh-b_form", function (nodes) {
           nodes.forEach(function (node) {
               autoForms.init(node,{
                   Validators:{
                       "maskphone": {
                           "keys": "()+-0123456789()-",
                           "errorMessage": "Type only numbers",
                           "validatorFunction": function validatorFunction(field) {
                               return field.valid = field.nodeLink.value.indexOf("_") < 0;
                           },
                           "keypressValidatorFunction": false
                       }
                   }
               });
           });


           ajaxForm(".tszh-b_form");
           
       });

       i(".tszh-block_open-water-form-link", function(nodes) {
           nodes.forEach(function (node) {
               node.addEventListener("click", function () {
                   yaCounter34259750.reachGoal("water-click");
                   jQuery(".tszh-block_waterform-hidden").addClass('visible');
               })
           });

       });

       i(".tszh-actions_close-water-form", function(nodes) {
           nodes.forEach(function (node) {
               node.addEventListener("click", function () {
                   jQuery(".tszh-block_waterform-hidden").removeClass('visible');
               })
           });
       });

       i(".tszh-block_waterform-hidden", function (nodes) {
          nodes[0].addEventListener("click", function (event) {
              event.stopPropagation();
          })
       });
       
       i("input[data-field-type='maskphone']", function (nodes) {
           nodes.forEach(function (node) {
               maskedInput(node).mask("+7 (999) 999-99-99");
           })
       });

       window.addEventListener("scroll", function(event) {
           var header = document.querySelector(".tszh-root_header");
           var scroll = header.offsetHeight+42 - window.pageYOffset;

           if (scroll<=130) {
               header.classList.add("attached");
           }
           if (window.pageYOffset <= 265){
               header.classList.remove("attached");
           }

           var newHeight = (372-window.pageYOffset);
           header.style.height = (newHeight<130?130:newHeight)+'px'

       })
   }
};

window.APP.init();